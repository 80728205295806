var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userIsEmployer)?_c('v-container',[_c('MInstruction',{attrs:{"instruction":{
        boilerInstructions: [
          'Enter in publically-editable Miro channels for hackathon participants to use',
          'Each entry should have team name and Miro channel URL'
        ],
        goal: 'Facilitate problem-solving between team members of hackathon participants '
      }}}),_c('h3',{staticStyle:{"margin-bottom":"1.2rem"}},[_vm._v("Team Channels")]),_vm._l((_vm.organizerData.channels),function(ref){
      var teamName = ref.teamName;
      var url = ref.url;
return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-start","gap":"1.2rem"}},[_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"outlined":"","rounded":"","value":teamName,"readonly":""}}),_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"outlined":"","rounded":"","value":url,"readonly":""}}),_c('v-btn',{attrs:{"x-large":"","rounded":"","outlined":"","size":"x-large","color":"error"},on:{"click":function($event){_vm.organizerData.channels = _vm.organizerData.channels.filter(
              function (x) { return x.teamName !== teamName || x.url !== url; }
            );
            _vm.$emit('save-progress');}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-start","gap":"1.2rem"}},[_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"label":"Team Name","outlined":"","rounded":""},model:{value:(_vm.nextTeamName),callback:function ($$v) {_vm.nextTeamName=$$v},expression:"nextTeamName"}}),_c('validation-provider',{attrs:{"rules":{ regex: _vm.urlRegex, required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"label":"Success Team URL","outlined":"","rounded":"","error-messages":errors},model:{value:(_vm.nextChannelUrl),callback:function ($$v) {_vm.nextChannelUrl=$$v},expression:"nextChannelUrl"}})]}}],null,false,2914704036)}),_c('v-btn',{attrs:{"disabled":_vm.nextTeamName === '' || _vm.nextChannelUrl === '',"x-large":"","rounded":"","outlined":"","size":"x-large"},on:{"click":_vm.addChannel}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],2):_c('v-container',[_c('MInstruction',{attrs:{"instruction":{
        boilerInstructions: [
          'Communicate with your Success Team',
          'Meet with them for support and help'
        ],
        goal: 'Communicate with your Success Team'
      }}}),_c('h2',{staticStyle:{"margin-bottom":"1.2rem","text-align":"center"}},[_vm._v("Team Channels")]),_vm._l((_vm.organizerData.channels),function(ref){
      var teamName = ref.teamName;
      var url = ref.url;
return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","gap":"1.2rem","margin-bottom":"0.8rem"}},[_c('v-btn',{staticStyle:{"flex":"1","background-color":"#ae90b0","font-weight":"bold"},attrs:{"href":url,"rounded":"","x-large":"","dark":"","target":"_blank","elevation":"0"}},[_c('h3',[_vm._v("Go To "+_vm._s(teamName)+" Channel "),_c('v-icon',[_vm._v("mdi-open-in-new")])],1)])],1)])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }